import React from "react"
import { Box } from "@mui/material"
import DTLogo from "./DTLogo"
import CurrentProjectStack from "./CurrentProjectStack"

const Header = ({ isLarge, currentProject }) => {
  return (
    <Box
      sx={{
        backgroundColor: (theme) => (isLarge ? theme.palette.blue[0] : theme.palette.blue[0]),
        color: (theme) => theme.palette.blue[4],
        height: isLarge ? "100%" : "auto",
        pb: 1,
        position: "fixed",
        zIndex: 1000,
        top: 0,
        left: 0,
        width: isLarge ? "calc(100% - 100% / 1.6)" : "100vw",
      }}>
      <Box
        sx={{
          height: isLarge ? "calc(100% - (100% / 1.6))" : "auto",
          backgroundColor: (theme) => theme.palette.blue[3],
          color: (theme) => theme.palette.blue[4],
          p: isLarge ? 5 : 2,
        }}>
        <DTLogo isLarge={isLarge} condensed={!isLarge} />
      </Box>
      <Box sx={{ height: isLarge ? "calc(100% / 1.6)" : "auto", backgroundColor: (theme) => theme.palette.blue[2], p: isLarge ? 5 : 0 }}>
        <CurrentProjectStack project={currentProject} isLarge={isLarge} />
      </Box>
    </Box>
  )
}

export default Header
