import React from "react"
import Section from "./Section"

const Codeplace = ({ isLarge }) => {
  const text = [
    "r/place was a social experiment hosted by Reddit, in which users could place a colored tile on a giant canvas every 5 minutes.",
    "The goal of this project is to create a similar canvas, but one that can be filled with blocks of code instead of colors.",
    "I am currently trying out various approaches to realize this, e.g. the programming languages that can be used in the canvas, how the connected blocks should be compiled together and how the compiled result should be displayed.",
  ]

  // const AppOverviewComp = <img style={{ borderRadius: "4px", width: "100%" }} src={AppOverview} alt="Project-Overview" />

  return (
    <Section
      title="r/place with Code"
      wip
      id="Codeplace"
      text={text}
      backgroundColor={(theme) => theme.palette.blue[2]}
      fontColor={(theme) => theme.palette.blue[4]}
      isLarge={isLarge}
    />
  )
}

export default Codeplace
