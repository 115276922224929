import React from "react"
import { Typography, Box } from "@mui/material"
import DTLogo from "../DTLogo"

const Timeline = ({ isLarge }) => {
  return isLarge ? (
    <>
      <Typography variant="h3" textAlign="left" paragraph m={5}>
        DT | Portfolio
      </Typography>
      {/* <Typography variant="caption" textAlign="right" paragraph mr={5}>
        scroll down icon on bottom rigth ( v )
      </Typography> */}
    </>
  ) : (
    <Box sx={{ mt: "60%" }}>
      <DTLogo isLarge={isLarge} />
      <Typography paragraph variant="h6" sx={{ fontWeight: "bold", textAlign: "center", mt: 4 }}>
        Dmitrij Tjumenzew
      </Typography>
      <Typography paragraph variant="h6" sx={{ fontWeight: "bold", textAlign: "center" }}>
        Portfolio & Projects
      </Typography>
    </Box>
  )
}

export default Timeline
