import React from "react"
import Section from "./Section"

const Elo = ({ isLarge }) => {
  const text = [
    "The Elo system is a widely used quantitative skill rating system in zero-sum games. It is the standard rating system in chess and many PvP video games.",
    "This project is an attempt to apply the Elo system to the world of competitive sports, beginning with Kyokushin Karate. The goal is to create a system in which every athlete in a particular sport holds a certain amount of points which reflect this atheletes ability / skill.",
    "By assigning a numerical value to reflect the skill of every athlete, it becomes easier to compare athletes, even if their careers are divided by large timespans or weight divisions, etc.",
    "Right now I am analyzing the publicly available datasets of e.g. MMA fights using Python and Jupyter Notebook, as well as Pandas.",
  ]

  // const AppOverviewComp = <img style={{ borderRadius: "4px", width: "100%" }} src={AppOverview} alt="Project-Overview" />

  return (
    <Section
      title="Elo System Application to Competitive Sports"
      wip
      id="Elo"
      text={text}
      backgroundColor={(theme) => theme.palette.blue[2]}
      fontColor={(theme) => theme.palette.blue[4]}
      isLarge={isLarge}
    />
  )
}

export default Elo
