import React from "react"
import { Paper, Grid, Typography, Box, Tooltip, alpha } from "@mui/material"

import ReactImg from "./StackLogos/React.png"
import PHPImg from "./StackLogos/PHP.png"
import MySQLImg from "./StackLogos/mysql.png"
import DockerImg from "./StackLogos/Docker.png"
import MUIImg from "./StackLogos/MUI.png"
import TSImg from "./StackLogos/TS.png"
import PythonImg from "./StackLogos/Python.png"
import CSSImg from "./StackLogos/CSS.png"
// import CImg from "./StackLogos/C.png"
import LaravelImg from "./StackLogos/Laravel.png"
import PandasImg from "./StackLogos/pandas.png"
import ReduxImg from "./StackLogos/Redux.png"
import GQLImg from "./StackLogos/GQL.png"
import SpringImg from "./StackLogos/spring.png"
import JavaImg from "./StackLogos/java.png"
import VueImg from "./StackLogos/vue.png"

const ReactLogo = { img: ReactImg, text: "React" }
const TSLogo = { img: TSImg, text: "TS" }
const CSSLogo = { img: CSSImg, text: "CSS" }
const ReduxLogo = { img: ReduxImg, text: "Redux" }
const VueLogo = { img: VueImg, text: "Vue" }

const PHPLogo = { img: PHPImg, text: "PHP" }
const LaravelLogo = { img: LaravelImg, text: "Laravel" }
const MySQLLogo = { img: MySQLImg, text: "MySQL" }
const GQLLogo = { img: GQLImg, text: "GraphQL" }
const SpringLogo = { img: SpringImg, text: "Spring Boot" }

const DockerLogo = { img: DockerImg, text: "Docker" }
const PythonLogo = { img: PythonImg, text: "Python" }
const PandasLogo = { img: PandasImg, text: "Pandas" }
const JavaLogo = { img: JavaImg, text: "Java" }
const MUILogo = { img: MUIImg, text: "MUI" }
// const CLogo = { img: CImg, text: "C" }

const CurrentProjectStack = ({ project, isLarge }) => {
  const stackImg = {
    frontend: [ReactLogo, ReduxLogo, TSLogo, VueLogo, CSSLogo],
    backend: [PHPLogo, LaravelLogo, MySQLLogo, GQLLogo, SpringLogo],
    // backend: [PHPLogo, LaravelLogo, MySQLLogo, GQLLogo, "placeholder"],
    other: [DockerLogo, PythonLogo, isLarge ? PandasLogo : "placeholder", JavaLogo, MUILogo],
  }

  const allImg = [...stackImg.frontend, ...stackImg.backend, ...stackImg.other].filter((i) => i !== "placeholder")

  const selectedImg = []

  switch (project) {
    case "Timeline":
      // selectedImg.push(ReactLogo, TS, MUI, PHP, Mysql, Docker)
      break
    case "Bookay":
      selectedImg.push(ReactLogo, TSLogo, MUILogo, MySQLLogo, DockerLogo, PHPLogo)
      break
    case "Elo":
      selectedImg.push(PythonLogo, PandasLogo)
      break
    case "Kyoquiz":
      selectedImg.push(PHPLogo, MySQLLogo, CSSLogo)
      break
    case "BXC":
      selectedImg.push(PHPLogo, MySQLLogo, CSSLogo)
      break
    case "Codeplace":
      selectedImg.push(ReactLogo, TSLogo, MUILogo, MySQLLogo, DockerLogo, PHPLogo)
      break
    case "Planeus":
      selectedImg.push(ReactLogo, TSLogo, MUILogo, DockerLogo, MySQLLogo, ReduxLogo, GQLLogo)
      break
    default:
      break
  }

  const IS_NOT_OVERVIEW = !!selectedImg.length

  const imgsToMap = IS_NOT_OVERVIEW ? selectedImg : allImg

  const imgHeightLarge = 40
  const imgHeightSmall = 30

  return isLarge ? (
    <Paper elevation={10} sx={{ backgroundColor: (theme) => theme.palette.blue[1], p: 2, width: "100%", height: "100%", mx: "auto" }}>
      <Typography paragraph variant="h6" sx={{ color: "#fff" }}>
        {project === "Timeline" ? "My Stack" : `Used in this project (${project})`}
      </Typography>
      <Grid container spacing={1} columns={5}>
        <Typography variant="body1" sx={{ ml: 1, mt: 2, color: "#fff" }}>
          Frontend
        </Typography>
        <Grid container item xs={5} columns={5} spacing={1}>
          {stackImg.frontend.map((i, index) => (
            <Grid item xs={1} key={index}>
              {i !== "placeholder" && (
                <Tooltip title={i.text} arrow placement="top">
                  <Box
                    sx={{
                      backgroundColor: selectedImg.includes(i) || !IS_NOT_OVERVIEW ? "rgba(72, 190, 255, 0.5)" : "",
                      opacity: selectedImg.includes(i) || !IS_NOT_OVERVIEW ? 1 : 0.5,
                      p: 1,
                      borderRadius: 2,
                      display: "flex",
                      justifyContent: "center",
                    }}>
                    <img src={i.img} height={imgHeightLarge} alt="tech" />
                  </Box>
                </Tooltip>
              )}
            </Grid>
          ))}
        </Grid>
        <Typography variant="body1" sx={{ ml: 1, mt: 2, color: "#fff" }}>
          API & Backend
        </Typography>
        <Grid container item xs={5} columns={5} spacing={1}>
          {stackImg.backend.map((i, index) => (
            <Grid item xs={1} key={index}>
              {i !== "placeholder" && (
                <Tooltip title={i.text} arrow placement="top">
                  <Box
                    sx={{
                      backgroundColor: selectedImg.includes(i) || !IS_NOT_OVERVIEW ? "rgba(72, 190, 255, 0.5)" : "",
                      opacity: selectedImg.includes(i) || !IS_NOT_OVERVIEW ? 1 : 0.5,
                      p: 1,
                      borderRadius: 2,
                      display: "flex",
                      justifyContent: "center",
                    }}>
                    <img src={i.img} height={40} alt="tech" />
                  </Box>
                </Tooltip>
              )}
            </Grid>
          ))}
        </Grid>
        <Typography variant="body1" sx={{ ml: 1, mt: 2, color: "#fff" }}>
          Other
        </Typography>
        <Grid container item xs={5} columns={5} spacing={1}>
          {stackImg.other.map((i, index) => (
            <Grid item xs={1} key={index}>
              {i !== "placeholder" && (
                <Tooltip title={i.text} arrow placement="top">
                  <Box
                    sx={{
                      backgroundColor: selectedImg.includes(i) || !IS_NOT_OVERVIEW ? "rgba(72, 190, 255, 0.5)" : "",
                      opacity: selectedImg.includes(i) || !IS_NOT_OVERVIEW ? 1 : 0.5,
                      p: 1,
                      borderRadius: 2,
                      display: "flex",
                      justifyContent: "center",
                    }}>
                    <img src={i.img} height={40} alt="tech" />
                  </Box>
                </Tooltip>
              )}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Paper>
  ) : (
    <Grid
      container
      spacing={0}
      columns={allImg.length / 2}
      sx={{ backgroundColor: (theme) => theme.palette.blue[1], p: 1, height: "100%", mx: "auto" }}>
      {IS_NOT_OVERVIEW && (
        <Grid item xs={12} sx={{ py: 1, pl: 1, my: 0.5 }}>
          <Typography sx={{ height: `${imgHeightSmall}px`, alignItems: "center", display: "flex", fontWeight: "bold" }}>
            Used in this Project ({project})
          </Typography>
        </Grid>
      )}
      {imgsToMap.map((i, index) => (
        <Grid item xs={1} key={index}>
          <Box
            sx={{
              backgroundColor: alpha("#1A3042", 0.8),
              p: 1,
              m: 0.5,
              borderRadius: 2,
              display: "flex",
              justifyContent: "center",
            }}>
            <img src={i.img} height={imgHeightSmall} alt="tech" />
          </Box>
        </Grid>
      ))}
    </Grid>
  )
}

export default CurrentProjectStack
