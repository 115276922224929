import React from "react"
import BookayLogo from "../Logos/Bookay.png"
import AppOverview from "../Screenshots/Bookay/app-overview.png"
import Section from "./Section"

const Bookay = ({ isLarge }) => {
  const text = [
    "Bookay is the schoolbook trading platform for high school students in Berlin.",
    "With over 2000 users on the platform since 2021, Bookay saves students and parents yearly up to 50% of the aquisition price for school books, which can be as high as 700 € in some states (Bundesländer).",
    "As a web app, it offers customization for every school. With paid licences and modules, Bookay adapts to the needs of every school and student.",
  ]

  const AppOverviewComp = <img style={{ borderRadius: "4px", width: "100%" }} src={AppOverview} alt="Project-Overview" />

  return (
    <Section
      id="Bookay"
      LogoSrc={BookayLogo}
      linkUrl="https://bookay-buecher.de"
      linkToDisplay="bookay-buecher.de"
      FirstImg={AppOverviewComp}
      text={text}
      backgroundColor={(theme) => theme.palette.blue[2]}
      fontColor={(theme) => theme.palette.blue[4]}
      linkColor="#98e39c"
      isLarge={isLarge}
    />
  )
}

export default Bookay
