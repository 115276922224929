import { red } from "@mui/material/colors"
import { createTheme } from "@mui/material/styles"

const theme = createTheme({
  palette: {
    primary: {
      main: "#98e39c",
    },
    secondary: {
      main: "#206e24",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#fff",
      dark: "#000",
    },
    blue: ["#1A3042", "#325E82", "#476781", "#768CA0", "#CFD6DC", "#D8DBE2"],
  },
})

export default theme
