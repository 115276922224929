import React from "react"
import { Paper, Grid, Typography, Box } from "@mui/material"

const cell = 20

const cellWidth = cell + "px"
const wholeWidth = cell * 7 + "px"
const wholeHeight = cell * 9 + "px"

const text = [
  ["D", "M", "I", "T", "R", "I", "J"],
  ["", "", "", "J", "", "", ""],
  ["", "", "", "U", "", "", ""],
  ["", "", "", "M", "", "", ""],
  ["", "", "", "E", "", "", ""],
  ["", "", "", "N", "", "", ""],
  ["", "", "", "Z", "", "", ""],
  ["", "", "", "E", "", "", ""],
  ["", "", "", "W", "", "", ""],
]

const DTLogo = ({ isLarge, condensed }) => {
  if (condensed) {
    return (
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Paper elevation={5}>
          <Typography
            sx={{
              color: "#fff",
              fontWeight: "bold",
              backgroundColor: (theme) => theme.palette.blue[1],
              p: 1,
              borderRadius: 1,
              width: "4em",
              textAlign: "center",
            }}>
            T
          </Typography>
        </Paper>
        <Typography sx={{ fontWeight: "bold" }}>DT | Portfolio</Typography>
      </Box>
    )
  }

  return isLarge ? (
    <Paper elevation={10} sx={{ backgroundColor: (theme) => theme.palette.blue[1], p: 2, mx: "auto", width: "100%" }}>
      <Grid container columns={7} sx={{ width: wholeWidth, mx: "auto", height: wholeHeight }}>
        {text.map((row, index) =>
          row.map((cell, index2) => (
            <Grid key={String(index) + String(index2)} item sx={{ height: cellWidth, width: cellWidth, color: "#ddd" }} xs={1}>
              <Typography textAlign="center" sx={{ lineHeight: cellWidth }}>
                {cell}
              </Typography>
            </Grid>
          ))
        )}
      </Grid>
    </Paper>
  ) : (
    <Paper elevation={10} sx={{ backgroundColor: (theme) => theme.palette.blue[1], p: 2, mx: "auto", width: "80%" }}>
      <Grid container columns={7} sx={{ width: wholeWidth, mx: "auto", height: wholeHeight }}>
        {text.map((row, index) =>
          row.map((cell, index2) => (
            <Grid key={String(index) + String(index2)} item sx={{ height: cellWidth, width: cellWidth, color: "#ddd" }} xs={1}>
              <Typography textAlign="center" sx={{ lineHeight: cellWidth }}>
                {cell}
              </Typography>
            </Grid>
          ))
        )}
      </Grid>
    </Paper>
  )
}

export default DTLogo
